import React from 'react'

function TrianguloDark () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <path d='M24 22H0L12 2z' />
    </svg>
  )
}

export default TrianguloDark
