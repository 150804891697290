import React from 'react'

function TrianguloBlanco () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <path d='M12 5.887L20.468 20H3.532L12 5.887zM12 2L0 22h24L12 2z' />
    </svg>
  )
}

export default TrianguloBlanco
