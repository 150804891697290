import React from 'react'

function Atomo () {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fillRule='evenodd'
      clipRule='evenodd'
    >
      <path d='M12 22.246c-.428 0-1.099-.709-1.688-2.276-.579.295-1.082.527-1.571.738C9.508 22.7 10.599 24 12 24c1.44 0 2.552-1.371 3.322-3.452-.485-.201-.997-.43-1.581-.719-.599 1.667-1.298 2.417-1.741 2.417zM7.291 20.03c-3.099 1.139-6.29 1.168-6.29-1.644 0-.939.435-2.257 1.796-4.082a23.157 23.157 0 011.937-2.248c1.531-1.555 3.256-3.05 5.505-4.599-1.083-.596-2.264-1.167-3.416-1.59-1.18-.435-2.219-.676-3.015-.676-.508 0-.886.107-1.009.288-.133.192-.138.784.445 1.843l.21-.013c1.047 0 1.898.866 1.898 1.933 0 1.067-.851 1.931-1.898 1.931-1.048 0-1.897-.864-1.897-1.931 0-.346.089-.67.245-.951C1.212 7.251 1 6.431 1 5.788c0-1.515 1.154-2.354 2.808-2.354 2.514 0 5.9 1.662 8.082 2.946 2.214-1.363 5.717-3.159 8.304-3.159 1.893 0 2.807 1.054 2.807 2.395 0 .939-.436 2.256-1.796 4.08-3.084 4.137-9.216 8.606-13.914 10.334zm14.917-4.305c.142-.27.22-.576.22-.902 0-1.068-.849-1.933-1.897-1.933s-1.897.865-1.897 1.933c0 1.066.849 1.93 1.897 1.93l.258-.017c.552 1.024.544 1.597.415 1.787-.124.181-.501.287-1.01.287-1.602 0-3.833-.944-5.27-1.657-.48.342-1.075.748-1.657 1.119 1.925 1.036 4.757 2.295 6.927 2.295 1.64 0 2.808-.83 2.808-2.354 0-.638-.211-1.455-.794-2.488zm-12.834.119h-.001l-.013-.01a38.251 38.251 0 01-3.317-2.631c-2.817 2.877-3.611 4.963-3.238 5.524.126.189.492.299 1.003.299 2.35 0 6.08-2.018 8.287-3.465 2.709-1.775 5.8-4.328 7.736-6.926 1.506-2.018 1.552-3.081 1.366-3.361-.126-.19-.491-.298-1.003-.298-1.952 0-4.924 1.459-6.636 2.447a40.109 40.109 0 013.52 2.616c-.408.415-.834.819-1.27 1.211a38.262 38.262 0 00-3.901-2.81 37.657 37.657 0 00-4.603 3.552 37.087 37.087 0 003.721 2.873 30.73 30.73 0 01-1.651.979zM12 10a2 2 0 11-.001 4.001A2 2 0 0112 10zm-1.739-5.828a26.025 26.025 0 00-1.581-.718C9.45 1.372 10.562 0 12 0c1.403 0 2.495 1.302 3.261 3.292-.49.212-.996.447-1.572.739-.587-1.567-1.258-2.275-1.689-2.275-.441 0-1.139.75-1.739 2.416z' />
    </svg>
  )
}

export default Atomo
